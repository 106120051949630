<template>
    <!-- <footer class="justify-content-between flex-wrap">
        <div class="col-12 col-xl-5 col-xxl-4 text-white">
            <small>Copyright © AMT Azienda Mobilità e Trasporti S.p.A.
                <br>Sede legale: via Montaldo 2, 16137 Genova
                <br>Codice fiscale, P.IVA e n° iscrizione Registro Imprese di Genova 037 839 30 104
                <br>Capitale sociale € 29.521.464,00 i.v.
                <br><a class="my-a text-white" href="mailto:amt.spa@pec.amt.genova.it">amt.spa@pec.amt.genova.it</a> - <a class="my-a text-white" href="mailto:amt.spa@amt.genova.it">amt.spa@amt.genova.it</a>
            </small>
            <div class="d-flex flex-wrap justify-content-between align-items-center mt-2">
                <div>
                    <img src="https://www.amt.genova.it/amt/wp-content/uploads/2016/07/certiquality.png" alt="certiquality">
                </div>
                <div class="d-flex flex-column">
                    <a class="mb-2 my-a text-white" href="https://www.amt.genova.it/amt/wp-content/uploads/2021/09/9001.pdf" target="_blank">UNI EN ISO 9001:2015</a>
                    <a class="mb-2 my-a text-white" href="https://www.amt.genova.it/amt/wp-content/uploads/2021/09/45001.pdf" target="_blank">UNI ISO 45001:2018</a>
                    <a class="my-a text-white" href="https://www.amt.genova.it/amt/wp-content/uploads/2018/02/certificato_eccellenza_gold.pdf" target="_blank">ECCELLENZA GOLD</a>
                </div>
                <div class="d-flex flex-column">
                    <a class="mb-2 my-a text-white" href="https://www.amt.genova.it/amt/wp-content/uploads/2021/09/14001.pdf" target="_blank">UNI EN ISO 14001:2015</a>
                    <a class="mb-2 my-a text-white" href="https://www.amt.genova.it/amt/wp-content/uploads/2021/09/50001.pdf" target="_blank">ISO 50001:2011</a>
                    <a class="my-a text-white" href="https://www.amt.genova.it/amt/wp-content/uploads/2021/09/37001.pdf" target="_blank">UNI ISO 37001</a>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-5 col-xxl-4 d-flex flex-column justify-content-around link">
            <div class="credits">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/photo-credits/">Photo credits</a>
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/dichiarazione-di-accessibilita/">Accessibilità</a>
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/clienti/contattaci/">URP</a>
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/amt-istituzionale/privacy/">Policy privacy AMT</a>
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/note-legali/">Note Legali</a>
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/siti-tematici/">Siti Tematici</a>
            </div>
            <div class="social">
                <span class="text-white">Seguici su</span>
                <a target="_blank" href="https://www.youtube.com/channel/UC2C1SQyeZjhbFtuoE9jVZjA">
                    <i class="fab fa-youtube"></i>
                </a>
                <a target="_blank" href="https://it.linkedin.com/company/amt-genova">
                    <i class="fab fa-linkedin-in"></i>
                </a>
                <a target="_blank" href="https://smart.comune.genova.it/genovaalert">
                    <i class="fab fa-telegram-plane"></i>
                </a>
                <a target="_blank" href="https://www.facebook.com/amtgenovatrasportopubblico/">
                    <i class="fab fa-facebook-f"></i>
                </a>
            </div>
            <div class="genova">
                <a href="http://www.visitgenoa.it/" target="_blank">
                    <img src="https://www.amt.genova.it/amt/wp-content/uploads/2021/01/logo_genova2.png">
                </a>
            </div>
        </div>
    </footer> -->
    <footer class="justify-content-center footer-small">
        <img src="../../../assets/img/tenants/ila.png">
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    footer {
        display: flex;
        padding: 20px;
        background-color: #006881;

        small,
        a {
            font-size: 12px;
        }

        .my-a:hover {
            color: #f08013 !important;
        }

        .credits {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .social {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            a {
                margin: 0 10px;
                border-radius: 50%;
                color: #006881;
                background-color: white;

                i {
                    padding: 15px;
                }
            }
        }
        .genova {
            display: flex;
            justify-content: flex-end;
        }
    }
    .footer-small {
        display: flex;
        background-color: transparent;
        padding: 35px;
    }

    @media screen and (max-width: 1200px) {
        footer {
            .genova,
            .social {
                justify-content: center;
            }

            .link {
                margin-top: 40px;
            }

            .social {
                margin: 30px 0;
            }
        }
    }

    @media screen and (max-width: 576px) {
        footer {
            display: none;

            .credits {
                display: none;
            }

            .social {
                margin-top: 0;
                span {
                    display: none;
                }
            }
        }
        .footer-small {
            display: flex;
        }
    }
</style>