<template>
    <header>
        <!-- <div class="nav-top">
            <div class="d-flex align-items-center">
                <div class="col-7 col-xl-6 offset-xl-2 d-flex justify-content-between align-items-center">
                    <a class="my-a text-white text-uppercase" href="https://www.amt.genova.it/amt/clienti/contattaci/">Parla con noi</a>
                    <a class="my-a text-white text-uppercase" href="https://www.amt.genova.it/amt/regolamenti/">Regolamenti</a>
                    <a class="my-a text-white text-uppercase" href="https://www.amt.genova.it/amt/mappa-del-sito/">Mappa del sito</a>
                    <a class="my-a text-white text-uppercase" href="https://www.amt.genova.it/amt/area-riservata/">Area riservata</a>
                </div>
                <form action="https://www.amt.genova.it/amt" class="col-5 col-xl-3 offset-xl-1 d-flex justify-content-end">
                    <input class="me-1" type="search" aria-label="Search">
                    <button class="btn text-white" type="submit">Cerca</button>
                </form>
            </div>
        </div> -->
        <div class="d-flex justify-content-center align-items-center header-top">
            <div class="col-4">
                <a href="https://www.amt.genova.it/amt/">
                    <img src="../../../assets/img/tenants/f3aa64fa-cdc6-46de-ae37-c8890b9e527d.svg" class="w-100" alt="AZIENDA MOBILITA' E TRASPORTI SpA">
                </a>
            </div>
            <!-- <div class="col-md-8">
                <div class="d-flex justify-content-end align-items-center social">
                    <span class="text-white">Seguici su</span>
                    <a target="_blank" href="https://www.youtube.com/channel/UC2C1SQyeZjhbFtuoE9jVZjA">
                        <i class="fab fa-youtube"></i>
                    </a>
                    <a target="_blank" href="https://it.linkedin.com/company/amt-genova">
                        <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a target="_blank" href="https://smart.comune.genova.it/genovaalert">
                        <i class="fab fa-telegram-plane"></i>
                    </a>
                    <a target="_blank" href="https://www.facebook.com/amtgenovatrasportopubblico/">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-4">
                    <a href="https://www.amt.genova.it/amt/">
                        <img class="w-100" src="https://www.amt.genova.it/amt/wp-content/uploads/2021/06/Famiglie-2830x200.png">
                    </a>
                </div>
            </div> -->
        </div>
        <!-- <nav>

            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/trasporto-multimodale/">Rete e orari</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/app-amt/">App AMT</a>
                    </li>
                    <li>
                        <div class="dropdown dropdown-item cursor-pointer d-flex justify-content-between align-items-center">
                            <span>Servizio urbano</span>
                            <span>></span>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/linee-bus-urbane/">Linee bus</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/metropolitana/">Metropolitana</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/funicolari/">Funicolari</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/ascensori/">Ascensori</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/servizi-integrativi/">Servizi integrativi</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/drinbus/">Drinbus</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/taxibus/">Taxibus</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/genova-airlink/">Genova Airlink</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/volabus/">Volabus</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/navebus/">Navebus</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/servizio-disabili/">Servizio disabili</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown dropdown-item cursor-pointer d-flex justify-content-between align-items-center">
                            <span>Servizio provinciale</span>
                            <span>></span>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/linee-bus-provinciali/">Linee bus provinciali</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/chiama-il-bus/">Chiama il bus</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/linee-bus-provinciali/servizi-stagionali/">Servizi Stagionali</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/ferrovia-genova-casella/">Ferrovia Genova Casella</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/trasporto-multimodale/corsie-riservate/">Corsie riservate</a>
                    </li>
                </ul>
            </div>

            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/tariffe/">Tariffe</a>
                <ul class="dropdown-menu">
                    <li>
                        <div class="dropdown dropdown-item cursor-pointer d-flex justify-content-between align-items-center">
                            <span>Tariffe servizio urbano <br>(Comune di Genova)</span>
                            <span>></span>
                            <ul class="dropdown-menu dropdown-menu-left">
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/biglietti-e-abbonamenti/citypass-amt-nuovo-facile-on-line/">CityPass AMT</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/biglietti-e-abbonamenti/abbonamenti/">Abbonamenti servizio urbano</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/tariffe/abbonamenti-integrati/">Abbonamenti Integrati AMT <br> FGC e Servizio Trasporto <br> Provinciale</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/biglietti-e-abbonamenti/biglietti/">Biglietti</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/biglietti-e-abbonamenti/acquisto-online-e-via-sms/">Acquisto online e via SMS</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/biglietti-e-abbonamenti/mini-carnet-web/">Mini Carnet Web</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/rete-di-vendita-urbana/">Rete di vendita urbana</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/accesso-abbonamento-aziendale/">Area riservata abbonamenti aziendali</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/biglietti-e-abbonamenti/controllo-validita-abbonamento-belt/">Controllo validità abbonamento</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <div class="dropdown dropdown-item cursor-pointer d-flex justify-content-between align-items-center">
                            <span>Tariffe servizio provinciale</span>
                            <span class="ms-1">></span>
                            <ul class="dropdown-menu dropdown-menu-left">
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/tariffe/tariffe-servizio-provinciale/">Scopri tutte le tariffe</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/tariffe/abbonamenti-servizio-provinciale/">Abbonamenti servizio provinciale</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/tariffe/abbonamenti-integrati/">Abbonamenti Integrati AMT <br> FGC e Servizio Trasporto <br> Provinciale</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="https://www.amt.genova.it/amt/tariffe/rete-di-vendita-provinciale/">Rete di vendita provinciale</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/clienti/">Clienti</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/contattaci/">Parla con noi</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/esattoria-e-sanzioni/">Sanzioni</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/pagamento-sanzioni-online/">Sanzioni pagamento online</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/servizi-agli-abbonati/">Servizi agli abbonati</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/servizi-al-cliente/">Servizi al cliente</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/scopri-genova-con-amt/">Scopri Genova con AMT</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/clienti/pedestribus/">PedestriBus</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/adotta-un-bus/">Adotta un bus</a>
                    </li>
                </ul>
            </div>

            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/amt-istituzionale/">AMT istituzionale</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/amt-in-cifre/">AMT in cifre</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/amtinnova/">AMTinnova</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/il-modello-organizzativo-d-lgs-23101/">Il Modello Organizzativo D.lgs 231/01</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/carta-della-mobilita/">Carta della Mobilità</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/adotta-un-bus/">Adotta un bus</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/missione-e-visione-amt/">Missione e Visione AMT</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/campagne-istituzionali/">Campagne istituzionali</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/studi-e-progetti/">Studi e progetti</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/privacy/">Policy privacy AMT</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/qualita/">Sistema di Gestione Integrato</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="/amt/wp-content/uploads/2020/07/AMT_statuto-sociale.pdf">Statuto sociale</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/alternanza-scuola-lavoro/">Alternanza scuola lavoro</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/amt-e-le-associazioni-dei-consumatori/">AMT e le Associazioni dei consumatori</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/accesso-ai-documenti-amministrativi/">Accesso ai documenti amministrativi</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/storia/">Storia</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/galleria-fotografica-di-oggi/">Galleria fotografica di oggi</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/amt-istituzionale/galleria-fotografica-ieri/">Galleria fotografica di ieri</a>
                    </li>
                </ul>
            </div>

            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/amministrazione-trasparente/">Amministrazione trasparente</a>
            </div>
            
            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/area-stampa/">Stampa e notizie</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/area-stampa/comunicati-stampa/">Comunicati stampa</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/area-stampa/succede-con-amt/">Succede…con AMT</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/area-stampa/accredito-giornalisti/">Accredito giornalisti</a>
                    </li>
                </ul>
            </div>

            <div class="dropdown">
                <a class="my-a text-white" href="https://www.amt.genova.it/amt/fornitori-e-appalti/">Fornitori e appalti</a>
                <ul class="dropdown-menu">
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/fornitori-e-appalti/albo-fornitori/">Albo fornitori</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/fornitori-e-appalti/sistemi-di-qualificazione/">Sistemi di Qualificazione</a>
                    </li>
                    <li>
                        <a class="dropdown-item" href="https://www.amt.genova.it/amt/fornitori-e-appalti/bandi-di-gara/">Bandi di gara</a>
                    </li>
                </ul>
            </div>
        </nav> -->
    </header>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    nav,
    .nav-top {
        background-color: #f08013;
    }

    .nav-top {
        padding: 2px 10px;

        a {
            font-size: 14px;
        }

        input {
            padding: 5px;
            border: 1px solid gray;
            border-radius: 5px;
            outline: none;
        }

        button {
            border-radius: 0;
            background-color: #006881;
        }
    }
    .header-top {
        justify-content: space-between;
        padding: 20px;
        background-color: #338499;

        .social {
            a {
                margin: 0 10px;
                border-radius: 50%;
                color: #006881;
                background-color: white;

                i {
                    padding: 15px;
                }
            }
        }
    }

    nav {
        display: flex;
        position: relative;
        border-radius: 10px 10px 0 0;
        
        .dropdown{
            padding: 10px 40px;

            .dropdown-menu {
                display: block;
                top: 68px;
                left: 0;
                padding-top: 0;
                padding-bottom: 0;
                visibility: hidden;
                opacity: 0;
                border-radius: 0;
                background-color: #006881;
                transition: transform .3s cubic-bezier(0.88,-0.72, 0, 1), opacity .3s ease-in-out;
                transform: translateY(-2rem);
                
                li {
                    .dropdown {
                        padding: 0.25rem 1rem;

                        .dropdown-menu {
                            display: block;
                            top: -1px;
                            left: 205px;
                            padding-top: 0;
                            padding-bottom: 0;
                            visibility: hidden;
                            opacity: 0;
                            border-radius: 0;
                            background-color: #006881;
                            transition: transform .3s cubic-bezier(0.88,-0.72, 0, 1), opacity .3s ease-in-out;
                            transform: translateY(-2rem);
                        }

                        .dropdown-menu-left {
                            left: 237px
                        }

                        &:hover {
                            .dropdown-menu {
                                visibility: visible;
                                margin-top: 0;
                                opacity:1;
                                transform: translateY(0);
                            }
                        }
                    }

                    &:not(&:last-child) {
                        border-bottom: 1px solid lightgray;
                    }
                }


                .dropdown-item {
                    color: lightgray;

                    &:hover {
                        color: white;
                        background-color: transparent;
                    }
                }
            }

            &:first-child {
                border-radius: 10px 0 0 0;
            }

            &:last-child {
                border-radius: 0 10px 0 0;
            }

            &:hover {
                background-color: #006881;
                
                .dropdown-menu {
                    visibility: visible;
                    margin-top: 0;
                    opacity:1;
                    transform: translateY(0);
                }
            }

            &:not(&:first-child) {
                border-left: 1px solid gray;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        nav{
            .dropdown {
                padding: 10px 30px;
            }
        }
    }
    
    @media screen and (max-width: 992px) {
        nav,
        .nav-top {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        .header-top {
            justify-content: center;
            background-color: #47aeb3;

            img {
                transform: scale(1.7);
            }
        }
        .col-md-8 {
            display: none;
        }
    }
</style>