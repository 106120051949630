<template>
    <div class="backdrop" :class="this.$route.name == 'EmailService' ? 'small' : ''">
        <div class="container p-0">
            <AMTHeader/>
                <div class="d-flex flex-wrap">
                    <div class="col-12 col-lg-3 col-xl-4 rounded-start bg-brand"></div>
                    <div class="col-12 col-lg-6 col-xl-4 rounded bg-brand">
                        <Login v-if="this.$route.name == 'Login'"/>
                        <Sign v-if="this.$route.name == 'Sign'"/>
                        <EmailService v-if="this.$route.name == 'EmailService'"/>
                        <ResetPassword v-if="this.$route.name == 'ResetPassword'"/>
                    </div>
                    <div class="col-12 col-lg-3 col-xl-4 justify-content-center align-items-center position-relative rounded-end bg-white col-content" :class="[(this.$route.name == 'EmailService' || this.$route.name == 'ResetPassword'  ? 'small' : ''),
                    (this.$route.name == 'Sign' ? 'big' : '')]">
                        <img src="@/assets/img/tenants/frog.png" class="logo-img w-100">
                        <img src="@/assets/img/tenants/ila.png" class="brand-icon">
                    </div>
                </div>
            <AMTFooter/>
        </div>
    </div>
</template>

<script>
import AMTHeader from '../Header.vue'
import Login from '@/components/Login.vue'
import Sign from '@/components/Sign.vue'
import EmailService from '@/components/EmailService.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import AMTFooter from '../Footer.vue'
export default {
    name: 'AMTAuth',
    components: {
        AMTHeader,
        Login,
        Sign,
        EmailService,
        ResetPassword,
        AMTFooter
    }
}
</script>

<style lang="scss" scoped>
    .backdrop {
        background-color: #338499;

        &.small {
            height: 100vh;
        }
    }

    .rounded {
        border-radius: 0 !important;
    }
    .rounded-start {
        border-radius: 25px 0 0 25px !important;
    }
    .rounded-end {
        border-radius: 0 25px 25px 0 !important;
    }

    .col-content {
        display: flex;
        position: relative;
        overflow: hidden;
        
        .logo-img {
            transform: scale(2.8);
        }

        &.big {
            .logo-img {
                transform: scale(3.5);
            }
        }
        &.small {
            .logo-img {
                transform: scale(2.3) rotate(11deg);
            }
        }

        &::before {
            position: absolute;
            content: '';
            left: 0;
            width: 0;
            height: 0;
            bottom: 0;
            border-right: 231px solid transparent;
            border-top: 704px solid #47aeb3;
            z-index: 2;
        }
        &.big::before {
            border-right: 255px solid transparent;
            border-top: 966px solid #47aeb3;
        }
        &.small::before {
            border-right: 221px solid transparent;
            border-top: 340px solid #47aeb3;
        }

    }

    .brand-icon {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
    }

    @media screen and (min-width: 768px) {
        .bg-brand {
            background-color: #47aeb3;
        }
    }
    @media screen and (max-width: 768px) {
        .backdrop {
            height: 100vh;
            background-color: #47aeb3;
        }
    }
    @media screen and (max-width: 992px) {
        .col-content {
            display: none;
        }

        .rounded {
            border-radius: 25px !important;
            background-position: left;
        }
    }
    @media screen and (max-width: 1199px) {
        .col-content {

            &::before,
            &.small::before {
                border-right: 160px solid transparent;
            }

            &.big::before {
                border-right: 178px solid transparent;
            }

        }
    }
    @media screen and (min-width: 1400px) {
        .col-content {

            &::before {
                border-right: 270px solid transparent;
            }

            &.big::before {
                border-right: 284px solid transparent;
                border-top:66 solid #47aeb3;
            }
        
            &.small::before {
                border-right: 242px solid transparent;
                border-top: 340px solid #47aeb3;
            }
        }
    }
</style>